<template>
	<div id="content">
		<Header v-bind:infos="infos" />
		<main>
			<!--Section caractéristiques générales-->
			<div class="container caracteristiquesGeneralesProjet">
				<div class="row">
					<!--Le commanditaire du projet-->
					<div
						class="commanditaireProjet col-sm-12 col-md-12 col-lg-4 col-xl-4"
					>
						<div>
							<h2>COMMANDITAIRE</h2>
							<p>Projet pour la Licence Professionnelle CIASIE</p>
						</div>
					</div>

					<!--La date de réalisation du projet-->
					<div
						class="dateProjet offset-lg-1 offset-xl-1 col-sm-12 col-md-12 col-lg-3 col-xl-3"
					>
						<div>
							<h2>ANNÉES</h2>
							<p>2020 - 2021</p>
						</div>
					</div>

					<!--Les services apportés pour ce projet-->
					<div class="servicesProjet col-sm-12 col-md-12 col-lg-4 col-xl-4">
						<div>
							<h2>SERVICES</h2>
							<p>
								Développement front-end/back-end, création d'une API, webdesign,
								communication
							</p>
						</div>
					</div>
				</div>
			</div>

			<!--Section présentation du projet-->
			<div class="container">
				<div class="row">
					<p class="contenuProjet col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Le projet Racing Maze est un travail réalisé sur plusieurs mois en
						groupe de cinq personnes lors de mon année de Licence
						Professionnelle Conception-Intégration d'Applications et Services
						Web pour l'Entreprise (CIASIE). Encadré par notre tuteur Monsieur
						Leroux, celui-ci consiste à créer une application web de gaming
						confrontant plusieurs joueurs.<br /><br />
						Pour cela, notre tuteur nous a proposé un concept de base de
						création aléatoire d'un labyrinthe dans lequel s'affrontent
						plusieurs joueurs. Ainsi, le but général du jeu est de récupérer
						tous les coffres dans un labyrinthe ce qui fait apparaître une clé
						nécessaire afin de sortir de celui-ci le plus rapidement possible
						dans le temps imparti.<br /><br />
						Pour mener à bien ce projet, nous avons commencé par nous organiser
						et à effectuer plusieurs réunions avec notre tuteur. J'ai par la
						suite occupé le rôle de chef de projet en veillant au bon avancement
						du projet et en actualisant notamment un Trello réalisé via la
						méthode Kanban. Nous avons ensuite travailler sur l'analyse des
						besoins, les maquettes ainsi que la conception avec par exemple la
						création des Use case avant de vraiment nous tourner vers la
						création de l'application.<br /><br />
						Concernant la partie technique, nous avons utilisé du Javascript
						pour la création du jeu en lui-même avec la génération aléatoire du
						labyrinthe, des coffres etc. Nous l'avons couplé avec Vue.js et le
						framework Semantic UI pour créer la partie front-end. Nous avons
						aussi réalisé en parallèle un modèle de données utilisant MongoDB
						pour le stockage ainsi qu'une API via Node.js que nous utilisons
						avec Ajax sur le côté client pour faire communiquer l'application.
						Pour finir, nous avons utilisé la bibliothèque Socket.io qui nous
						permet de créer des intéractions en temps réel avec plusieurs
						clients ce qui fait fonctionner la partie multijoueur.
					</p>

					<!--Image 1-->

					<img
						src="../assets/racingmaze/tableau_kanban.png"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Aperçu du tableau Kanban"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Aperçu du tableau Kanban
					</p>

					<!--Image 2-->

					<img
						src="../assets/racingmaze/use_case.png"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Exemple d'un Use Case, ici la création d'une partie"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Exemple d'un Use Case, ici la création d'une partie
					</p>

					<!--Image 3-->

					<img
						src="../assets/racingmaze/architecture_technique.png"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Schéma de l'architecture technique du projet"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Schéma de l'architecture technique du projet
					</p>

					<!--Vidéo 1-->

					<iframe
						class="col-sm-12 col-md-12 col-lg-12 col-xl-12 videoYoutube"
						height="700"
						src="https://www.youtube.com/embed/iLnc6Vkxn5o"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
						style="border: none"
					></iframe>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Vidéo de présentation finale du projet Racing Maze
					</p>
				</div>
			</div>

			<div class="container">
				<!--Bouton voir les autres projets-->
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
						<router-link to="projets" class="boutonVoirTousLesProjets"
							>VOIR LES AUTRES PROJETS</router-link
						>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
	metaInfo: function() {
		return {
			title: "Antonin Winterstein | Racing Maze",
			meta: [
				{
					name: "description",
					content:
						'Découvrez le projet de l\'application "Racing Maze" auquel Antonin Winterstein a contribué !',
				},
			],
		};
	},
	components: {
		Header,
	},
	data() {
		return {
			infos: {
				titrePage: "RACING MAZE - APPLICATION WEB DE GAMING",
				urlImage: require("../assets/projets/fond_racing_maze.jpg"),
			},
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style>
@import "../styles/pageProjet.css";
</style>
